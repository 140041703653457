@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

.background-image {
    background-image: url(./assets/images/background.jpg);
    background-position: center;
    /* background-attachment: fixed; */
    background-size: cover;
}

.gradient-background {
    background-image: url(./assets/images/gradient-background.jpg);
    background-position: center;
    /* background-attachment: fixed; */
    background-size: cover;
}

.gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}